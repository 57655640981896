.listBord {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.itemBord {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 8px 0 8px 16px;
}

.name {
  flex: 1;
  word-break: break-word;
}

.btnBoard {
  margin-left: auto;
  flex-shrink: 0;
}
