.rbc-event-label {
  display: none;
}

.rbc-button-link {
  margin-bottom: 4px;
  padding: 4px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.1),
    0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 7px 0px rgba(0, 0, 0, 0.06);
}
