:root {
  --white: #ffffff;
  --black: #000000;
  --accent-orange: #ff6600;
  --orange-secondary: #f38840;
  --accent-red: #f91f2b;
  --error: red;
  --success-green: #4caf50;
  --gray: #808080;
  --gray-middle: rgb(235, 228, 228);
  --gray-darkmiddle: rgb(158, 158, 158);
  --neutral-gray: rgb(192, 192, 192);
  --neutral-gray2: rgb(176, 190, 197);
  --gray-light: #f4f4f4;

  // Кольори тексту
  // --text-dark: #333333;
  --text-light: #ffffff;
  --text-secondary: var(--gray);

  // Кольори фону
  --background-light: #f8f8f8;

  // Кольори статусів
  --status1: var(--accent-red);
  --status2: var(--accent-orange);
  --status3: var(--success-green);
  --status4: var(--accent-red);
  // --status5: ;

  // Кольори notification
  --error-notif: #d32f2f;
  --alert-notif: var(--orange-secondary);

  // Кольори таблиці
  --table-header: var(--black);
}
