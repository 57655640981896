@import "styles/_colors";

.messagePage {
  min-height: calc(100% - 112px);
  padding-bottom: 80px;
  box-sizing: border-box;
}

.messagePageCalendar {
  height: calc(100% - 112px);
  padding-bottom: 24px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.header {
  background-color: var(--table-header);
  color: var(--text-light);
  font-weight: bold;
}

.row {
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-gray2);
  transition: background-color 0.3s;
}

.cell {
  padding: 10px;
  width: calc(100% / 3);
  display: inline-block;
  box-sizing: border-box;
}

.statusCell {
  font-weight: bold;
}

.calendar {
  height: 100%;
}
