.avatarWrap {
  position: relative;
}

.btnAvatar {
  position: absolute;
  top: 0;
  left: calc(50% + 70px);
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
