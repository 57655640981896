@import "styles/_colors";

.wrap {
  position: relative;
  margin: 0 14px 0 14px;
  border-radius: 10px;
  padding: 14px;
  padding-right: 38px;
  margin-bottom: 10px;
  background-image: linear-gradient(to bottom right, #ee82ee, #ff0000);
  -webkit-box-shadow: 2px 6px 14px -4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 6px 14px -4px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 6px 14px -4px rgba(34, 60, 80, 0.2);

  cursor: pointer;
}

.wrapOpacity {
  background-image: linear-gradient(
    to bottom right,
    rgba(238, 130, 238, 0.5),
    rgba(255, 0, 0, 0.5)
  );
}

.info {
  width: 100%;
}

.name {
  color: var(--white);
  font-size: 28px;
  text-shadow: 3px 3px 1px rgba(0, 0, 0, 0.2);
}

.message {
  font-size: 16px;
  color: var(--white);
}

.count {
  font-size: 26px;
}

.qr {
  width: 110px;
  height: 110px;
  margin-bottom: 6px;
}

.incorrect {
  color: var(--white);
  background-color: var(--error);
  border-radius: 4px;
  display: inline-block;
  padding: 0 4px;
}
